import type { Component } from 'vue'
import { createVueApp } from '../vue'
import UploadDialog from './UploadDialog.vue'
import { type AvvUploadParams } from './types'

class AvvUploadManager {
  upload<T>(params: AvvUploadParams<T>) {
    return new Promise<T>((resolve) => {
      const container = document.createElement('div')
      document.body.insertAdjacentElement('beforeend', container)

      const appProps = Object.assign(
        {
          onCloseWithResponse: (response: unknown) => {
            resolve(response as T)

            container.remove()
          },
          onClose: () => {
            container.remove()
          }
        },
        params
      )

      const app = createVueApp(UploadDialog as Component, appProps)
      app.mount(container)
    })
  }
}

const _avv_upload_manager = new AvvUploadManager()

const _avv_upload = <T>(params: AvvUploadParams<T>) => {
  return window.avv_upload_manager.upload<T>(params)
}

window.avv_upload_manager = _avv_upload_manager
window.avv_upload = _avv_upload

declare global {
  interface Window {
    avv_upload_manager: typeof _avv_upload_manager
    avv_upload: typeof _avv_upload
  }

  const avv_upload_manager: typeof _avv_upload_manager
  const avv_upload: typeof _avv_upload
}

export default {}
